import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
//import Layout from '../components/Layout';
 
const stripePromise = loadStripe('pk_live_ZIa6bTar1nM3chnSDAvFzlTz00enciBIE6');


const MindfulnessPage = () => {
    stripePromise.then(stripe=>{
    stripe.redirectToCheckout({
        lineItems: [{
            price: 'price_1Hu5uiEjPv8mYPwIckPP1d2T', // Replace with the ID of your price
            quantity: 1,
        }],
        mode: 'payment',
        successUrl: 'https://www.sacredgarden.cc/Success',
        cancelUrl: 'https://sacredgarden.cc/',
        });
    })
    return <></>
}


export default MindfulnessPage;
